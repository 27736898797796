import React from 'react';

const Background = () => (
    <div className="o-Background-Landing">
        <div className="o-Background-homewrap">
            <div className="o-Background-homeinner" />
        </div>
    </div>
);

export default Background;